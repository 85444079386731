<template>
  <div class="right-menu shipping-right">
    
    <ValidationObserver ref="updateShippingObserver">
      <div class="db-blue-section" v-loading.fullscreen.lock="regionLoader">

        <div class="db-container">

          <subHeader pageName="EDIT SHIPPING"/>

          <form id="update-shipping-information" @submit.prevent="validate('update')">

            <!-- region basic information block starts here -->
            <div class="shipping-box">
              <div class="row">
                <div class="col-md-12">
                  <!-- region name block starts here -->
                  <div class="RegionTitleBox">
                    <h3>REGION DETAIL</h3>
                    <p>Add the Destination in this Region</p>
                  </div>
                  <div class="mainEditHeading">
                    <label for="region-name">Region Name <span>*</span></label>
                    <ValidationProvider name="region name" :rules="{required:true,max:60}" v-slot="{ errors }">
                      <input 
                        type="text" 
                        id="region-name" 
                        v-model="region_name" 
                        placeholder="Region name"
                      >
                      <br>
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <!-- region name block ends here -->

                  <!-- destination block starts here -->
                  <div class="region-content">
                    <!-- country block starts here -->
                    <div class="regionLabel">
                      <label for="countries">Countries <span>*</span></label>
                    </div>
                    <ValidationProvider name="country" rules="required" v-slot="{ errors }">
                      <Multiselect
                        v-model="selectedCountries"
                        id="countries"
                        :clearOnSelect="true"
                        :options="countriesList.length > 0 ? countriesList : []"
                        label="name"
                        :multiple="true"
                        :object="true"
                        track-by="name"
                        :hideSelected="false"
                        :showNoResults="true"
                        placeholder="Select Country"
                        :showOptions="true"
                        :searchable="true"
                        openDirection="bottom"
                        @select="countrySelection"
                        @remove="countryRemoval"
                      >
                        <span slot="noResult">No country found matching the searched keyword.</span>
                      </Multiselect>
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                    <!-- country block ends here -->

                    <!-- states block starts here -->
                    <template v-if="statesGroup.length != 0">
                      <div class="regionLabel">
                        <label for="states">States <span>*</span></label>
                      </div>
                      <ValidationProvider name="states" rules="required" v-slot="{ errors }">
                        <Multiselect
                          v-model="selectedStates"
                          id="states"
                          :clearOnSelect="true"
                          :options="statesGroup"
                          group-values="states" 
                          group-label="country_name" 
                          :group-select="true"
                          label="name"
                          track-by="name"
                          :hideSelected="false"
                          :showNoResults="true"
                          :multiple="true"
                          :object="true"
                          :showOptions="true"
                          :searchable="true"
                          :loading="statesLoading"
                          openDirection="bottom"
                          :placeholder="statesLoading ? 'Loading States....' : 'Select States'"
                          @select="stateSelection"
                          @remove="stateRemoval"
                        >
                          <span slot="noResult">No state found matching the searched keyword</span>
                          <span slot="noOptions">To load the states, please select a country.</span>
                        </Multiselect>
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </template>
                    <!-- states block ends here -->

                    <!-- cities block starts here -->
                    <template v-if="citiesGroup.length != 0">
                      <div class="regionLabel">
                        <label for="cities">Cities <span>*</span></label>
                      </div>
                      <ValidationProvider name="cities" rules="required" v-slot="{ errors }">
                        <Multiselect
                          v-model="selectedCities"
                          id="cities"
                          :clearOnSelect="true"
                          :options="citiesGroup"
                          group-values="cities" 
                          group-label="state_name"
                          :group-select="true"
                          label="name"
                          track-by="name"
                          :hideSelected="false"
                          :showNoResults="true"
                          :multiple="true"
                          :object="true"
                          :showOptions="true"
                          :searchable="true"
                          :loading="citiesLoading"
                          openDirection="bottom"
                          :placeholder="citiesLoading ? 'Loading Cities....' : 'Select Cities'"
                          @select="citySelection"
                          @remove="cityRemoval"
                        >
                          <span slot="noResult">No city found matching the searched keyword</span>
                          <span slot="noOptions">To load the cities, please select a state.</span>
                        </Multiselect>
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </template>
                    <!-- cities block ends here -->

                    <button type="button">Add Region</button>
                  </div>
                  <!-- destination block ends here -->
                  
                </div>
              </div>
            </div>
            <!-- region basic information block ends here -->

            <!-- region advance information block starts here -->
            <div class="shipping-box">
              <div class="row">
                <div class="col-md-12">
                  <h2>SHIPPING CALCULATION</h2>
                  <div class="paymentForm">
                    <div class="region-content">
                      <div class="advanceChecked">
                        <input
                          type="checkbox"
                          v-model="isCodSupport"
                          name="defaultUnchecked1"
                          id="defaultUnchecked1"
                        />
                        <label for="defaultUnchecked1">Do You Support COD payment method.</label>
                      </div>
                    </div>
                    <div class="region-content" v-show="isCodSupport">
                      <div class="advanceChecked">
                        <input
                          type="checkbox"
                          v-model="isNonCodDifferent"
                          name="defaultUnchecked2"
                          id="defaultUnchecked2"
                        />
                        <label for="defaultUnchecked2">Are the charges different for COD and non-COD?</label>
                      </div>
                    </div>
                    <div class="notePargraph" v-show="isCodSupport && isNonCodDifferent">
                      <p>
                        <span>Note</span> Now you can add different prices For COD
                        on the Shipment Page.
                      </p>
                    </div>
                  </div>
                  <div class="region-content" style="margin-top: 30px">
                    <p>Select How Shipping Rates Are Calculated In This Region</p>
                  </div>
                </div>
              </div>
              <!-- shipping methods block starts here -->
              <div class="row">
                <div class="col-md-6">

                  <!-- active shipping method block starts here -->
                  <div class="select-menu" v-on:click="showDropDown = !showDropDown">
                    <div class="select-img">
                      <img :src="activeShipping.img" />
                    </div>
                    <div class="free-ship-content">
                      <h3>{{ activeShipping.title }}</h3>
                      <p>{{ activeShipping.description }}</p>
                      <p v-if="activeShipping.note" style="color:red">{{ activeShipping.note }}</p>
                    </div>
                    <div class="select-menu-arrow">
                      <i class="fa fa-angle-down" aria-hidden="true"></i>
                    </div>
                  </div>
                  <!-- active shipping method block ends here -->

                  <!-- shipping methods drop down block starts here -->
                  <div class="dropdown-calculation" v-if="showDropDown">
                    <div class="select-menu-dropdown" @click.prevent="changeShippingMethodHandle('without-confirmation',shipping)" v-for="shipping in shippingOptions" :key="shipping.id">
                      <div class="select-img">
                        <img :src="shipping.img" />
                      </div>
                      <div class="free-ship-content">
                        <h3>{{ shipping.title }}</h3>
                        <p>{{ shipping.description }}</p>
                      </div>
                    </div>
                  </div>
                  <!-- shipping methods drop down block ends here -->
                  
                </div>
              </div>

              <!-- shipping method forms start here -->
              <form action="" class="shippingDetailForm">
                <div class="row">
                  <div class="col-md-12 col-lg-6">

                    <!-- flat rate form starts here -->
                    <div class="flatrateBox" v-if="activeShipping.id == 5">
                      <div v-for="(item, rule_index) in shippingRules" :key="rule_index">
                        <hr v-show="rule_index > 0" />
                        <div class="cancelBtn">
                          <i class="fa fa-times" aria-hidden="true" @click="removeRuleHandle(item,rule_index)"></i>
                        </div>
                        <ValidationProvider name="shipping option name" :vid="'D-name' + item.id " :rules="activeShipping.id == 5 ? {required:true,max:250} : ''" v-slot="{ errors }">
                          <div class="tableFromFieldBox mb-0">
                            <label :for="`doName-${item.id}`">Delivery Option Name <span>*</span></label>
                          </div>
                          <div class="exclimatoryForm">
                            <input
                              type="text"
                              :id="`doName-${item.id}`"
                              class="form-control"
                              placeholder="e.g. Standard Shipping"
                              v-model="item.name"
                              @input="formatHandle('option name',item)"
                              @blur="checkDuplicateShippingOption(item)"
                            />
                            <img src="../../../assets/images/exclimatory-mark.png" alt=""/>
                            <span class="text-danger">{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                        <div class="row">
                          <div :class=" isNonCodDifferent ? 'col-md-6' : 'col-md-7'">
                            <ValidationProvider name="duration" :vid="'FR-duration' + item.id " :rules="activeShipping.id == 5 ? {required:true,max:250} : ''" v-slot="{ errors }">
                              <div class="tableFromFieldBox mb-0">
                                <label :for="`doDays-${item.id}`">Delivery Days <span>*</span></label>
                              </div>
                              <div class="exclimatoryForm">
                                <input
                                  type="text"
                                  :id="`doDays-${item.id}`"
                                  class="form-control"
                                  placeholder="e.g. 3 - 5 Delivery Days"
                                  @input="formatHandle('delivery details',item)"
                                  @blur="trimHandle('delivery details',item)"
                                  v-model="item.delivery_details"
                                />
                                <img src="../../../assets/images/exclimatory-mark.png" alt=""/>
                                <span class="text-danger">{{ errors[0] }}</span>
                              </div>
                            </ValidationProvider>
                          </div>
                          <div :class=" isNonCodDifferent ? 'col-md-3': 'col-md-5'">
                            <ValidationProvider name="price" :vid="'FRCOD-price' + item.id" :rules="activeShipping.id == 5 ? {required:true,regex:/^(?:\d*\.\d{1,2}|\d+)$/,max_value:9999999} : ''" v-slot="{ errors }">
                              <div class="tableFromFieldBox mb-0">
                                <label :for="`FCODprice + ${item.id}`">Price <span>*</span></label>
                              </div>
                              <div class="exclimatoryForm">
                                <input
                                  type="text"
                                  :id="`FCODprice + ${item.id}`"
                                  class="form-control"
                                  :placeholder="settings.currency_symbol ? `${settings.currency_symbol}` : ''"
                                  v-model="item.prices[0].price"
                                />
                                <img src="../../../assets/images/exclimatory-mark.png" alt=""/>
                                <span class="text-danger">{{ errors[0] }}</span>
                              </div>
                            </ValidationProvider>
                          </div>
                          <div class="col-md-3" v-if="isNonCodDifferent">
                            <ValidationProvider name="non code price" :vid="'FRNonCOD-price' + item.id" :rules="activeShipping.id == 5 ? {required:true,regex:/^(?:\d*\.\d{1,2}|\d+)$/,max:9999999} : ''" v-slot="{ errors }">
                              <div class="tableFromFieldBox mb-0">
                                <label :for="`FNonCODprice + ${item.id}`">Price (Non-COD) <span>*</span></label>
                              </div>
                              <div class="exclimatoryForm">
                                <input
                                  type="text"
                                  class="form-control"
                                  :id="`FNonCODprice + ${item.id}`"
                                  :placeholder="settings.currency_symbol ? `${settings.currency_symbol}` : ''"
                                  v-model="item.prices[0].non_cod_price"
                                />
                                <img src="../../../assets/images/exclimatory-mark.png" alt=""/>
                                <span class="text-danger">{{ errors[0] }}</span>
                              </div>
                            </ValidationProvider>
                          </div>
                        </div>
                      </div>
                      <div class="addMoreForm">
                        <button type="button" @click.prevent="addMoreForm">+ Add Another Shipping Option</button>
                      </div>
                    </div>
                    <!-- flat rate form ends here -->

                    <!-- rate by weight form starts here -->
                    <div class="rateByWeightForm" v-if="activeShipping.id == 2">
                      <div v-for="(item, rule_index) in shippingRules" :key="rule_index">
                        <ValidationProvider name="shipping option" :vid="'name' + item.id" :rules="activeShipping.id == 2 ? {required:true,max:250} : ''" v-slot="{ errors }">
                          <div class="cancelBtn">
                            <i class="fa fa-times" aria-hidden="true" @click="removeRuleHandle(item,rule_index)"></i>
                          </div>
                          <div class="tableFromFieldBox mb-0">
                            <label :for="`rBW + ${item.id}`">Shipping Option <span>*</span></label>
                          </div>
                          <div class="exclimatoryForm">
                            <input
                              type="text"
                              :id="`rBW + ${item.id}`"
                              class="form-control"
                              placeholder="e.g. Standard shipping"
                              v-model="item.name"
                              @input="formatHandle('option name',item)"
                              @blur="checkDuplicateShippingOption(item)"
                            />
                            <img src="../../../assets/images/exclimatory-mark.png" alt=""/>
                            <span class="text-danger">{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                        <div class="row">
                          <div class="col-md-4">
                            <ValidationProvider name="duration" :vid="'duration' + item.id" :rules="activeShipping.id == 2 ? {required:true,max:250} : ''" v-slot="{ errors }">
                              <div class="tableFromFieldBox mb-0">
                                <label :for="`rBW_DDays + ${item.id}`">Delivery Days <span>*</span></label>
                              </div>
                              <div class="exclimatoryForm">
                                <input
                                  type="text"
                                  :id="`rBW_DDays + ${item.id}`"
                                  class="form-control"
                                  placeholder="e.g. 3 - 5 Delivery days"
                                  @input="formatHandle('delivery details',item)"
                                  @blur="trimHandle('delivery details',item)"
                                  v-model="item.delivery_details"
                                />
                                <span class="text-danger">{{ errors[0] }}</span>
                              </div>
                            </ValidationProvider>
                          </div>
                          <div :class="weight_index == 0 ? 'col-md-8':'offset-md-4 col-md-8'" v-for="(weight, weight_index) in item.weights" :key="weight_index">
                            <div class="exclimatoryForm">
                              <div class="weightRangeMainBox">
                                <div class="weightRangeCol">
                                  <div class="tableFromFieldBox mb-0">
                                    <label for="doName">Weight Range <span>*</span></label>
                                  </div>
                                  <div class="weightRange2">
                                    <ValidationProvider name="from" :vid="'range-from' + weight.id" :rules="activeShipping.id == 2 && weight_index != 0 ? {required:true,regex:/^(?:\d*\.\d{1,2}|\d+)$/,min_value:0}  : ''" v-slot="{ errors }">
                                      <div class="rangeEnterValue">
                                        <input
                                          type="number"
                                          class="form-control"
                                          placeholder="from"
                                          v-model="weight.from"
                                          :disabled="weight_index == 0 ? true: false"
                                          @input="inpFrom(weight_index, rule_index)"
                                        />
                                      </div>
                                      <span class="text-danger">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                    <ValidationProvider name="to" :vid="'range-to' + weight.id" :rules="activeShipping.id == 2 && weight_index != item.weights.length - 1 ? {required:true,regex:/^(?:\d*\.\d{1,2}|\d+)$/,min_value: (parseFloat(weight.from) + 0.01).toFixed(2)}  : ''" v-slot="{ errors }">
                                      <div class="rangeEnterValue">
                                        <input
                                          type="number"
                                          class="form-control"
                                          :placeholder="weight_index == item.weights.length-1 ? 'And Upto' : 'to'"
                                          v-model="weight.to"
                                          :disabled="weight_index == item.weights.length-1 ? true: false"
                                          @input="inpTo(weight_index, rule_index)"
                                        />
                                      </div>
                                      <span class="text-danger">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                  </div>
                                </div>
                                <div class="weightRange3">
                                  <ValidationProvider name="price" :vid="'RBWCODprice' + weight.id" :rules="activeShipping.id == 2 ? {required:true,regex:/^(?:\d*\.\d{1,2}|\d+)$/,max_value:9999999} : ''" v-slot="{ errors }">
                                    <div class="tableFromFieldBox mb-0">
                                      <label :for="`RBWCODprice + ${weight.id}`">Price <span>*</span></label>
                                    </div>
                                    <div class="exclimatoryForm">
                                      <input
                                        type="number"
                                        :id="`RBWCODprice + ${weight.id}`"
                                        class="form-control"
                                        :placeholder="settings.currency_symbol ? `${settings.currency_symbol}` : ''"
                                        v-model="weight.price"
                                      />
                                      <img src="../../../assets/images/exclimatory-mark.png" alt=""/>
                                      <span class="text-danger">{{ errors[0] }}</span>
                                    </div>
                                  </ValidationProvider>
                                </div>
                                <div class="weightRange3" v-if="isNonCodDifferent">
                                  <ValidationProvider name="non code price" :vid="'RBWNonCODprice' + weight.id" :rules="isNonCodDifferent && activeShipping.id == 2 ? {required:true,regex:/^(?:\d*\.\d{1,2}|\d+)$/,max_value:9999999} : ''" v-slot="{ errors }">
                                    <div class="tableFromFieldBox mb-0">
                                      <label :for="`RBWNonCODprice + ${weight.id}`">Price (Non-COD) <span>*</span></label>
                                    </div>
                                    <div class="exclimatoryForm">
                                      <input
                                        type="number"
                                        :id="`RBWNonCODprice + ${weight.id}`"
                                        class="form-control"
                                        :placeholder="settings.currency_symbol ? `${settings.currency_symbol}` : ''"
                                        v-model="weight.non_cod_price"
                                      />
                                      <img src="../../../assets/images/exclimatory-mark.png" alt=""/>
                                      <span class="text-danger">{{ errors[0] }}</span>
                                    </div>
                                  </ValidationProvider>
                                </div>
                                <div class="closeRangeBtn" v-if="weight_index > 1">
                                  <i class="fa fa-times" aria-hidden="true" @click="removeRulePriceHandle(item,weight,weight_index)"></i>
                                </div>
                                <div class="addField" v-show="weight_index == (item.weights.length - 1)">
                                  <button type="button" @click.prevent="addFields(weight_index, rule_index)"><span>+</span></button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="addMoreForm">
                        <button type="button" @click="addMoreForm">+ Add Another Shipping Option</button>
                      </div>
                    </div>
                    <!-- rate by weight form ends here -->

                    <!-- rate by price form starts here -->
                    <div class="rateByWeightForm" v-if="activeShipping.id == 3">
                      <div v-for="(item, rule_index) in shippingRules" :key="rule_index">
                        <ValidationProvider name="shipping option" :vid="'name' + rule_index" :rules="activeShipping.id == 3 ? {required:true,max:250} : ''" v-slot="{ errors }">
                          <div class="cancelBtn">
                            <i class="fa fa-times" aria-hidden="true" @click="removeRuleHandle(item,rule_index)"></i>
                          </div>
                          <div class="tableFromFieldBox mb-0">
                            <label :for="`rBP_SName + ${rule_index}`">Shipping Option <span>*</span></label>
                          </div>
                          <div class="exclimatoryForm">              
                            <input
                              type="text"
                              :id="`rBP_SName + ${rule_index}`"
                              class="form-control"
                              placeholder="e.g. Standard shipping"
                              v-model="item.name"
                              @input="formatHandle('option name',item)"
                              @blur="checkDuplicateShippingOption(item)"
                            />
                            <img src="../../../assets/images/exclimatory-mark.png" alt=""/>
                            <span class="text-danger">{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                        <div class="row">
                          <div class="col-md-4">
                            <ValidationProvider name="duration" :vid="'duration' + rule_index" :rules="activeShipping.id == 3 ? {required:true,max:250} : ''" v-slot="{ errors }">
                              <div class="tableFromFieldBox mb-0">
                                <label :for="`rBP_DDays + ${rule_index}`">Delivery Days <span>*</span></label>
                              </div>
                              <div class="exclimatoryForm">
                                <input
                                  type="text"
                                  :id="`rBP_DDays + ${rule_index}`"
                                  class="form-control"
                                  placeholder="e.g. 3 - 5 Delivery days"
                                  @input="formatHandle('delivery details',item)"
                                  @blur="trimHandle('delivery details',item)"
                                  v-model="item.delivery_details"
                                />
                                <span class="text-danger">{{ errors[0] }}</span>
                              </div>
                            </ValidationProvider>
                          </div>
                          <div :class="price_index == 0 ? 'col-md-8':'offset-md-4 col-md-8'" v-for="(price, price_index) in item.prices" :key="price_index">
                            <div class="exclimatoryForm">
                              <div class="weightRangeMainBox">
                                <div class="weightRangeCol">
                                  <div class="tableFromFieldBox mb-0">
                                    <label>Price Range <span>*</span></label>
                                  </div>
                                  <div class="weightRange2">
                                    <ValidationProvider name="from" :vid="'range-from' + price.id" :rules="activeShipping.id == 3 && price_index != 0 ? {required:true,regex:/^(?:\d*\.\d{1,2}|\d+)$/,min_value: 0}  : ''" v-slot="{ errors }">
                                      <div class="rangeEnterValue">
                                        <input
                                          type="number"
                                          class="form-control"
                                          placeholder="from"
                                          v-model="price.from"
                                          :disabled="price_index == 0 ? true: false"
                                          @input="inpFrom(price_index, rule_index)"
                                        />
                                      </div>
                                      <span class="text-danger">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                    <ValidationProvider name="to" :vid="'range-to' + price.id" :rules="activeShipping.id == 3 && price_index != item.prices.length - 1 ? {required:true,regex:/^(?:\d*\.\d{1,2}|\d+)$/,min_value: (parseFloat(price.from) + 0.01).toFixed(2)}  : ''" v-slot="{ errors }">
                                      <div class="rangeEnterValue">
                                        <input
                                          type="number"
                                          class="form-control"
                                          :placeholder="price_index == item.prices.length-1 ? 'And Upto' : 'to'"
                                          v-model="price.to"
                                          :disabled="price_index == item.prices.length-1 ? true: false"
                                          @input="inpTo(price_index, rule_index)"
                                        />
                                      </div>
                                      <span class="text-danger">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                  </div>
                                </div>
                                <div class="weightRange3">
                                  <ValidationProvider name="price" :vid="'RBPprice' + price.id" :rules="activeShipping.id == 3 ? {required:true,regex:/^(?:\d*\.\d{1,2}|\d+)$/,max_value:9999999} : ''" v-slot="{ errors }">
                                    <div class="tableFromFieldBox mb-0">
                                      <label :for="`RBRprice + ${price.id}`">Price <span>*</span></label>
                                    </div>
                                    <div class="exclimatoryForm">
                                      <input
                                        type="text"
                                        :id="`RBRprice + ${price.id}`"
                                        class="form-control"
                                        :placeholder="settings.currency_symbol ? `${settings.currency_symbol}` : ''"
                                        v-model="price.price"
                                      />
                                      <img src="../../../assets/images/exclimatory-mark.png" alt=""/>
                                    </div>
                                    <span class="text-danger">{{ errors[0] }}</span>
                                  </ValidationProvider>
                                </div>
                                <div class="weightRange3" v-if="isNonCodDifferent">
                                  <ValidationProvider name="price" :vid="'RBPNONCODprice' + price.id" :rules="isNonCodDifferent && activeShipping.id == 3 ? {required:true,regex:/^(?:\d*\.\d{1,2}|\d+)$/,max_value:9999999} : ''" v-slot="{ errors }">
                                    <div class="tableFromFieldBox mb-0">
                                      <label :for="`RBPNONCODprice + ${price.id}`">Price (Non-COD) <span>*</span></label>
                                    </div>
                                    <div class="exclimatoryForm">
                                      <input
                                        type="text"
                                        :id="`RBPNONCODprice + ${price.id}`"
                                        class="form-control"
                                        :placeholder="settings.currency_symbol ? `${settings.currency_symbol}` : ''"
                                        v-model="price.non_cod_price"
                                      />
                                      <img src="../../../assets/images/exclimatory-mark.png" alt=""/>
                                    </div>
                                    <span class="text-danger">{{ errors[0] }}</span>
                                  </ValidationProvider>
                                </div>
                                <div class="closeRangeBtn" v-if="price_index > 1">
                                  <i class="fa fa-times" aria-hidden="true" @click="removeRulePriceHandle(item,weight,price_index)"></i>
                                </div>
                                <div class="addField" v-show="price_index == (item.prices.length - 1)">
                                  <button type="button" @click.prevent="addFields(price_index, rule_index)"><span>+</span></button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="addMoreForm">
                        <button type="button" @click.prevent="addMoreForm">+ Add Another Shipping Option</button>
                      </div>
                    </div>
                    <!-- rate by price form ends here -->

                    <!-- store pickup only form starts here -->
                    <div class="storePickUpForm" v-if="activeShipping.id == 4">
                      <div v-for="(item, rule_index) in shippingRules" :key="rule_index">
                        <hr v-show="rule_index > 0" />
                        <div class="cancelBtn">
                          <i class="fa fa-times" aria-hidden="true" @click="removeRuleHandle(item,rule_index)"></i>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <ValidationProvider name="store name" :rules="activeShipping.id == 4 ? {required:true,max:250} : ''" v-slot="{ errors }">
                              <div class="tableFromFieldBox mb-0">
                                <label :for="`sName + ${rule_index}`">Store Name <span>*</span></label>
                              </div>
                              <div class="exclimatoryForm">
                                <input
                                  type="text"
                                  :id="`sName + ${rule_index}`"
                                  class="form-control"
                                  placeholder="Store name"
                                  v-model="item.store_name"
                                  @input="formatHandle('store name',item)"
                                  @blur="checkDuplicateShippingOption(item)"
                                />
                                <img src="../../../assets/images/exclimatory-mark.png" alt=""/>
                                <span class="text-danger">{{ errors[0] }}</span>
                              </div>
                            </ValidationProvider>
                          </div>
                          <div class="col-md-6">
                            <ValidationProvider name="store number" :rules="activeShipping.id == 4 ? {required:true,max:25} : ''" v-slot="{ errors }">
                              <div class="tableFromFieldBox mb-0">
                                <label :for="`sPhoneNo + ${rule_index}`">Store Contact No.<span>*</span></label>
                              </div>
                              <div class="exclimatoryForm">
                                <input
                                  type="number"
                                  :id="`sPhoneNo + ${rule_index}`"
                                  class="form-control"
                                  placeholder="Store contact no."
                                  @input="formatHandle('store contact',item)"
                                  @blur="trimHandle('store contact',item)"
                                  v-model="item.store_contact_no"
                                />
                                <img src="../../../assets/images/exclimatory-mark.png" alt=""/>
                                <span class="text-danger">{{ errors[0] }}</span>
                              </div>
                            </ValidationProvider>
                          </div>
                          <div class="col-md-6">
                            <ValidationProvider name="store location" :rules="activeShipping.id == 4 ? {required:true,max:250} : ''" v-slot="{ errors }">
                              <div class="tableFromFieldBox mb-0">
                                <label :for="`sLocation + ${rule_index}`">Store Address <span>*</span></label>
                              </div>
                              <div class="exclimatoryForm">
                                <input
                                  type="text"
                                  :id="`sLocation + ${rule_index}`"
                                  class="form-control"
                                  placeholder="Store address"
                                  @input="formatHandle('store location',item)"
                                  @blur="trimHandle('store location',item)"
                                  v-model="item.store_address"
                                />
                                <img src="../../../assets/images/exclimatory-mark.png" alt=""/>
                                <span class="text-danger">{{ errors[0] }}</span>
                              </div>
                            </ValidationProvider>
                          </div>
                          <div class="col-md-6">
                            <ValidationProvider name="store city" :rules="activeShipping.id == 4 ? {required:true} : ''" v-slot="{ errors }">
                              <div class="tableFromFieldBox mb-0">
                                <label :for="`sCity + ${rule_index}`">Store City <span>*</span></label>
                              </div>
                              <div class="exclimatoryForm">
                                <multiselect 
                                  v-model="item.store_city_id" 
                                  :options="selectedCities ? selectedCities : []"
                                  deselect-label="Can't remove this value" 
                                  label="name"
                                  track-by="id"
                                  :hideSelected="false"
                                  :showNoResults="true"
                                  placeholder="Select one"
                                  :searchable="false" 
                                  :allow-empty="false"
                                >
                                  <span slot="noOptions">Cities must be selected first.</span>
                                </multiselect>
                                <span class="text-danger">{{ errors[0] }}</span>
                              </div>
                            </ValidationProvider>
                          </div>
                        </div>
                      </div>
                      <div class="addMoreForm">
                        <button type="button" @click.prevent="addMoreForm">+ Add Another Store details</button>
                      </div>
                    </div>
                    <!-- store pickup only form ends here -->

                  </div>
                </div>
              </form>
              <!-- shipping method forms ends here -->

              <!-- shipping methods block ends here -->
            </div>
            <!-- region advance information block ends here -->

            <!-- region advance settings block starts here -->
            <div class="shipping-box settingBox">
              <div class="row">
                <div class="col-md-12">
                  <h2>ADVANCE SETTINGS</h2>
                  <!-- handling fee block starts here -->
                  <div class="region-content">
                    <div class="advanceChecked">
                      <input
                        type="checkbox"
                        name="defaultUnchecked5"
                        id="defaultUnchecked5"
                        v-model="handlingFee.isActive"
                      />
                      <label for="defaultUnchecked5">Add A Handling Fee To Every Order</label>
                    </div>
                    <!-- <ValidationProvider name="handling fee" :rules="handlingFee.isActive ? {required:true,regex:/^(?:\d*\.\d{1,2}|\d+)$/} : ''" v-slot="{ errors }">
                      <div class="advanceChecked" v-if="handlingFee.isActive">
                        <input
                          type="text"
                          name="handlingfee"
                          id="handlingfee"
                          placeholder="Add Handling Fee"
                          v-model="handlingFee.fee"
                        />
                      </div>
                      <span class="text-danger" v-if="handlingFee.isActive && errors[0]">{{ errors[0] }}</span>
                    </ValidationProvider> -->
                  </div>
                  <!-- handling fee block ends here -->

                  <div class="region-content">
                    <div class="advanceChecked">
                      <input
                        type="checkbox"
                        name="defaultUnchecked6"
                        id="defaultUnchecked6"
                        required
                      />
                      <label for="defaultUnchecked6">Allow Store Pickup</label>
                    </div>
                  </div>
                  <div class="settingInfoParagraph">
                    <p>
                      Let Customers Pick Up Orders In Person As One Of The Delivery
                      Options In This Region.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <!-- region advance settings block ends here -->

            <!-- save shipping block starts here -->
            <div class="shippingBtn">
              <button type="button" @click.prevent="goBack()">CANCEL</button>
              <loaderBtn v-if="editShippingLoader"/>
              <button
                form="update-shipping-information" 
                button="submit" 
                @click.prevent="validate('update')" 
                v-else
              >
                SAVE
              </button>
            </div>
            <!-- save shipping block ends here -->

          </form>

        </div>
      </div>
     </ValidationObserver>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import _ from "lodash";
import "vue-multiselect/dist/vue-multiselect.min.css";
import Swal from 'sweetalert2';
import subHeader from "@/components/subHeader.vue";
import { Message, MessageBox, Loading } from "element-ui";
import { v4 as uuidv4 } from 'uuid';
import { mapGetters } from "vuex";
import loaderBtn from "@/components/loaderBtn.vue";

export default {
  metaInfo() {
    return {
      title: 'E-Commerce | Edit Shipping Region | YeetCommerce',
    };
  },
  data: () => ({
    region_name:'',
    temp_region_name:'',

    region_id:'',
    tempRegion:null,

    isCodSupport: true,
    isNonCodDifferent: false,

    showDropDown: false,
    handlingFee:{
      isActive:false,
      fee:''
    },
    countriesList:[],
    statesList:[],
    citiesList:[],
    statesGroup:[],
    citiesGroup:[],
    orgCountries:[],
    orgStates:[],
    orgCities:[],
    selectedCountries: [],
    selectedStates: [],
    selectedCities: [],
    statesLoading:false,
    citiesLoading:false,
    activeShipping: {
      id: 1,
      title: "Free Shipping",
      description: "Enhance customer satisfaction by offering free shipping on all orders.",
      img: "/images/free-shipping.png",
    },
    shippingOptions: [
      {
        id: 2,
        title: "Rate By Weight",
        description:"The total weight of the items determines the shipping cost of the order.",
        note:"Rate will automatically set to 0 if any of the products available in the cart have 0 weight",
        img: "/images/balance@2x.png",
      },
      {
        id: 3,
        title: "Rate By Price",
        description:"Shipping costs are calculated based on the total monetary value of the order.",
        img: "/images/cart@2x.png",
      },
      {
        id: 4,
        title: "Store Pickup Only",
        description: "Orders are available for pickup at available store locations only.",
        img: "/images/front-store@2x.png",
      },
      {
        id: 5,
        title: "Flat Rate",
        description: "Make shipping calculations easy with a fixed cost applied to each item ordered.",
        img: "/images/flat-rate@2x.png",
      },
    ],
    shippingRules: [],
    tempShippingRules: [],
    tempShippingRulePrices: [],
    rule: null,
    editShippingLoader:false,
    isTouched:false,
    isFound:false,
    regionLoader:false,
  }),
  components: {
    Multiselect,
    subHeader,
    loaderBtn
  },
  methods: {
    formatHandle(field,item){

      if(field == 'option name'){

        let tempOptionName = item.name;

        tempOptionName = tempOptionName.replace(/[^a-zA-Z0-9 -]/g, '');

        tempOptionName = tempOptionName.replace(/\s+/g, ' ');

        item.name = tempOptionName;

      }else if(field == 'delivery details'){

        let tempDeliveryDetail = item.delivery_details;

        tempDeliveryDetail = tempDeliveryDetail.replace(/[^a-zA-Z0-9 -]/g, '');

        tempDeliveryDetail = tempDeliveryDetail.replace(/\s+/g, ' ');

        item.delivery_details = tempDeliveryDetail;

      }else if(field == 'store name'){

        let tempStoreName = item.store_name;

        tempStoreName = tempStoreName.replace(/[^a-zA-Z0-9 -]/g, '');

        tempStoreName = tempStoreName.replace(/\s+/g, ' ');

        item.store_name = tempStoreName;

      }else if(field == 'store contact'){

        let tempStoreContactNo = item.store_contact_no;

        tempStoreContactNo = tempStoreContactNo.replace(/[^a-zA-Z0-9 -]/g, '');

        tempStoreContactNo = tempStoreContactNo.replace(/\s+/g, ' ');

        item.store_contact_no = tempStoreContactNo;

      }else if(field == 'store location'){

        let tempStoreAddress = item.store_address;

        tempStoreAddress = tempStoreAddress.replace(/[^a-zA-Z0-9 -]/g, '');

        tempStoreAddress = tempStoreAddress.replace(/\s+/g, ' ');

        item.store_address = tempStoreAddress;

      }

    },
    trimHandle(field,item){

      if(field == 'delivery details'){
        
        item.delivery_details = item.delivery_details.trim();

      }else if(field == 'store contact'){

        item.store_contact_no = item.store_contact_no.trim();

      }else if(field == 'store location'){
        
        item.store_address = item.store_address.trim();

      }

    },

    async countrySelection(item,i){
      
      if(this.orgCountries.some(country => country.id == item.id)){

        if(this.selectedStates.length > 0 ){

          if(this.statesList.length > 0){

            let countryIds = [];

            this.statesGroup = [];

            if(this.selectedCountries.length > 0){

              this.selectedCountries.forEach(country => {

                countryIds.push(country.id);

                let obj = {
                  country_id:country.id,
                  country_name: country.name + " (Selecting this option will select all the states available in this country.)",
                  states:[]
                }

                this.statesGroup.push(obj);

              });

              countryIds.push(item.id);

              let obj = {
                country_id:item.id,
                country_name: item.name + " (Selecting this option will select all the states available in this country.)",
                states:[]
              }

              if(this.statesGroup.some(groupObj => groupObj == obj)){

                this.statesGroup.push(obj);

              }


            }else{

              countryIds.push(item.id);

              let obj = {
                country_id:item.id,
                country_name: item.name + " (Selecting this option will select all the states available in this country.)",
                states:[]
              }

              if(this.statesGroup.some(groupObj => groupObj == obj)){

                this.statesGroup.push(obj);

              }

            }

            this.statesLoading = true;
            try{
              let res = await this.$axios.get('/country/states/all',{params:{countries:countryIds}})
              if(res.data.status_code == "1010"){

                this.statesList = res.data.states.concat(this.orgStates.filter(obj2 => !res.data.states.some(obj1 => obj1.id === obj2.id)));

                if(this.statesGroup.length > 0){

                  this.statesGroup.forEach(group => {

                    this.statesList.forEach(state =>{

                      if(state.country_id == group.country_id){

                        if(!group.states.some((groupedState) => groupedState.name == state.name)){

                          group.states.push(state);

                        }

                      }

                    });

                  });

                }

              }

            }catch(error){

              this.$message({
                type: "error",
                showClose: true,
                message: error.response ? error.response.data.message : error.message,
              });

            }finally{

              this.statesLoading = false;

            }

          }else{

            this.statesList = this.selectedStates;

            this.statesGroup = [];

            this.selectedCountries.forEach(country => {

              let obj = {
                country_id:country.id,
                country_name: country.name + " (Selecting this option will select all the states available in this country.)",
                states:[]
              }

              this.statesGroup.push(obj);

            });

            let obj = {
              country_id:item.id,
              country_name: item.name + " (Selecting this option will select all the states available in this country.)",
              states:[]
            }

            if(this.statesGroup.some(groupObj => groupObj == obj)){

              this.statesGroup.push(obj);

            }

            if(this.statesGroup.length > 0){

              this.statesGroup.forEach(group => {

                this.statesList.forEach(state =>{

                  if(state.country_id == group.country_id){

                    group.states.push(state);

                  }

                })

              })

            }
              
          }

        }else{

          if(this.statesList.length > 0){

            let countryIds = [];

            this.statesGroup = [];

            if(this.selectedCountries.length > 0){

              this.selectedCountries.forEach(country => {

                countryIds.push(country.id);
                
                let obj = {
                  country_id:country.id,
                  country_name: country.name + " (Selecting this option will select all the states available in this country.)",
                  states:[]
                }

                this.statesGroup.push(obj);

              });

              countryIds.push(item.id);

              let obj = {
                country_id:item.id,
                country_name: item.name + " (Selecting this option will select all the states available in this country.)",
                states:[]
              }

              if(this.statesGroup.some(groupObj => groupObj == obj)){

                this.statesGroup.push(obj);

              }

            }else{

              countryIds.push(item.id);

              let obj = {
                country_id:item.id,
                country_name: item.name + " (Selecting this option will select all the states available in this country.)",
                states:[]
              }

              this.statesGroup.push(obj);

            }

            this.statesLoading = true;
            try{
              let res = await this.$axios.get('/country/states/all',{params:{countries:countryIds}})
              if(res.data.status_code == "1010"){

                this.statesList = res.data.states.concat(this.orgStates.filter(obj2 => !res.data.states.some(obj1 => obj1.id === obj2.id)));

                if(this.statesGroup.length > 0){

                  this.statesGroup.forEach(group => {

                    this.statesList.forEach(state =>{

                      if(state.country_id == group.country_id){

                        if(!group.states.some((groupedState) => groupedState.name == state.name)){

                          group.states.push(state);

                        }

                      }

                    })

                  })

                }
              
              }
            }catch(error){

              this.$message({
                type: "error",
                showClose: true,
                message: error.response ? error.response.data.message : error.message,
              });

            }finally{

              this.statesLoading = false;

            }

          }else{

            let countryIds = [];

            this.statesGroup = [];

            if(this.selectedCountries.length > 0){

              this.selectedCountries.forEach(country => {

                countryIds.push(country.id);

                let obj = {
                  country_id:country.id,
                  country_name: country.name + " (Selecting this option will select all the states available in this country.)",
                  states:[]
                }

                this.statesGroup.push(obj);

              });

              countryIds.push(item.id);

              let obj = {
                country_id:item.id,
                country_name: item.name,
                states:[]
              }

              if(this.statesGroup.some(groupObj => groupObj == obj)){

                this.statesGroup.push(obj);

              }

            }else{

              countryIds.push(item.id);

              let obj = {
                country_id:item.id,
                country_name: item.name + " (Selecting this option will select all the states available in this country.)",
                states:[]
              }

              if(this.statesGroup.some(groupObj => groupObj == obj)){

                this.statesGroup.push(obj);

              }

            }

            this.statesLoading = true;
            try{
              let res = await this.$axios.get('/country/states/all',{params:{countries:countryIds}})
              if(res.data.status_code == "1010"){
              
                this.statesList = res.data.states.concat(this.orgStates.filter(obj2 => !res.data.states.some(obj1 => obj1.id === obj2.id)));

                if(this.statesGroup.length > 0){

                  this.statesGroup.forEach(group => {

                    this.statesList.forEach(state =>{

                      if(state.country_id == group.country_id){

                        if(!group.states.some((groupedState) => groupedState.name == state.name)){

                          group.states.push(state);

                        }

                      }

                    })

                  })

                }

              }
            }catch(error){

              this.$message({
                type: "error",
                showClose: true,
                message: error.response ? error.response.data.message : error.message,
              });

            }finally{

              this.statesLoading = false;

            }


          }

        }
        
      }else{

        let countryIds = [];

        this.statesGroup = [];

        if(this.selectedCountries.length > 0){

          this.selectedCountries.forEach(country => {

            countryIds.push(country.id);

            let obj = {
              country_id:country.id,
              country_name: country.name + " (Selecting this option will select all the states available in this country.)",
              states:[]
            }

            this.statesGroup.push(obj);

          });

          countryIds.push(item.id);

          let obj = {
              country_id:item.id,
              country_name: item.name + " (Selecting this option will select all the states available in this country.)",
              states:[]
            }

            if(this.statesGroup.some(groupObj => groupObj == obj)){

              this.statesGroup.push(obj);

            }

        }else{

          countryIds.push(item.id);

          let obj = {
            country_id:item.id,
            country_name: item.name + " (Selecting this option will select all the states available in this country.)",
            states:[]
          }

          if(this.statesGroup.some(groupObj => groupObj == obj)){

            this.statesGroup.push(obj);

          }

        }

        this.statesLoading = true;
        try{
          let res = await this.$axios.get('/country/states/all',{params:{countries:countryIds}})
          if(res.data.status_code == "1010"){
            
            if(this.selectedStates.length > 0){
              
              if(this.statesList.length > 0){

                if(this.statesGroup.length > 0){

                  this.statesGroup.forEach(group => {

                    res.data.states.forEach(state =>{

                      if(state.country_id == group.country_id){

                        if(!group.states.some((groupedState) => groupedState.name == state.name)){

                          group.states.push(state);

                        }

                      }

                    })

                  })

                }

              }else{

                if(this.statesGroup.length > 0){

                  this.statesGroup.forEach(group => {

                    res.data.states.forEach(state =>{

                      if(state.country_id == group.country_id){

                        if(!group.states.some((groupedState) => groupedState.name == state.name)){

                          group.states.push(state);

                        }

                      }

                    })
                    
                  })

                }

              }

            }else{

              if(this.statesList.length > 0){

                if(this.statesGroup.length > 0){

                  this.statesGroup.forEach(group => {

                    res.data.states.forEach(state =>{

                      if(!group.states.some((groupedState) => groupedState.name == state.name)){

                        group.states.push(state);
                        
                      }

                    })

                  })

                }

              }else{

                if(this.statesGroup.length > 0){

                  this.statesGroup.forEach(group => {

                    res.data.states.forEach(state =>{

                      if(!group.states.some((groupedState) => groupedState.name == state.name)){

                        group.states.push(state);

                      }

                    })

                  })

                }

              }

            }

          }
        }catch(error){

          this.$message({
            type: "error",
            showClose: true,
            message: error.response ? error.response.data.message : error.message,
          });

        }finally{

          this.statesLoading = false;

        }

      }
    },
    async countryRemoval(item,i){

      this.selectedStates = this.selectedStates.filter((state)=>{

        if(state.country_id != item.id){

          return state;

        }

      });

      this.statesList = this.statesList.filter((state)=>{

        if(state.country_id != item.id){

          return state;

        }

      })

      this.statesGroup = this.statesGroup.filter(group =>{

        if(group.country_id != item.id){

          return group;

        }

      })

      this.selectedCities = this.selectedCities.filter((city) => {

        if(city.country_id != item.id){

          return city;

        }

      });

      this.citiesList = this.selectedCities;

      this.citiesGroup = [];

      this.selectedStates.forEach(state => {

        let obj = {
          state_id:state.id,
          state_name: state.name + " (Selecting this option will select all the states available in this country.)",
          cities:[]
        }

        this.citiesGroup.push(obj);

      });

      this.citiesGroup.forEach(group => {

        this.citiesList.forEach(city =>{

          if(city.state_id == group.state_id){

            group.cities.push(city);

          }

        });

      });

    },
    async stateSelection(item,i){

      if(this.orgStates.some(state => state.id == item.id)){

        if(this.selectedCities.length > 0){

          this.citiesList = [...this.selectedCities,...this.orgCities.filter(city => city.state_id == item.id)];

          this.citiesGroup = [];

          let stateIds = [];

          this.selectedStates.forEach(state => {

            stateIds.push(state.id);

            let obj = {
              state_id:state.id,
              state_name: state.name + " (Selecting this option will select all the cities/districts available in this state.)",
              cities:[]
            }

            this.citiesGroup.push(obj);

          });

          let obj = {
            state_id:item.id,
            state_name: item.name + " (Selecting this option will select all the cities/districts available in this state.)",
            cities:[]
          }

          if(this.citiesGroup.some(groupObj => groupObj == obj)){

            this.citiesGroup.push(obj);

          }

          this.citiesGroup.forEach(group => {

            this.citiesList.forEach(city =>{

              if(city.state_id == group.state_id){

                if(!group.cities.some((groupedCity) => groupedCity.name == city.name)){

                  group.cities.push(city);

                }

              }

            })

          })

        }else{

          if(this.citiesList.length > 0){

            this.citiesList = [...this.citiesList,...this.orgCities.filter(city => city.state_id == item.id)];

            this.citiesGroup = [];

            let stateIds = [];

            this.selectedStates.forEach(state => {
              stateIds.push(state.id);

              let obj = {
                state_id:state.id,
                state_name: state.name + " (Selecting this option will select all the cities/districts available in this state.)",
                cities:[]
              }

              this.citiesGroup.push(obj);

            });

            let obj = {
              state_id:item.id,
              state_name: item.name + " (Selecting this option will select all the cities/districts available in this state.)",
              cities:[]
            }

            if(this.citiesGroup.some(groupObj => groupObj == obj)){

              this.citiesGroup.push(obj);

            }

            this.citiesGroup.forEach(group => {

              this.citiesList.forEach(city =>{

                if(city.state_id == group.state_id){

                  if(!group.cities.some((groupedCity) => groupedCity.name == city.name)){
                    
                    group.cities.push(city);

                  }

                }

              })

            })

          }else{

            this.citiesList = this.orgCities.filter(city => city.state_id == item.id);

            this.citiesGroup = [];

            let stateIds = [];

            this.selectedStates.forEach(state => {
              stateIds.push(state.id);

              let obj = {
                state_id:state.id,
                state_name: state.name + " (Selecting this option will select all the cities/districts available in this state.)",
                cities:[]
              }

              this.citiesGroup.push(obj);

            });

            let obj = {
              state_id:item.id,
              state_name: item.name + " (Selecting this option will select all the cities/districts available in this state.)",
              cities:[]
            }

            if(this.citiesGroup.some(groupObj => groupObj == obj)){

              this.citiesGroup.push(obj);
              
            }

            this.citiesGroup.forEach(group => {

              this.citiesList.forEach(city =>{

                if(city.state_id == group.state_id){

                  if(!group.cities.some((groupedCity) => groupedCity.name == city.name)){

                    group.cities.push(city);

                  }

                }

              })

            })

            this.citiesLoading = true;
            try{
              let cityRes = await this.$axios.get('/state/cities/all',{params:{states:stateIds}})
              if(cityRes.data.status_code == "1010"){

                if(this.citiesList.length > 0){

                  this.citiesList = [...this.citiesList,...cityRes.data.cities.filter(obj2 => !this.citiesList.some(obj1 => obj1.id === obj2.id))];

                  this.citiesList = this.citiesList.filter((city) =>{

                    if(stateIds.some(id => id == city.state_id));

                    return city;

                  })

                  this.citiesGroup.forEach(group => {

                    this.citiesList.forEach(city =>{

                      if(city.state_id == group.state_id){

                        if(!group.cities.some((groupedCity) => groupedCity.name == city.name)){
                          
                          group.cities.push(city);

                        }

                      }

                    })

                  })

                }

              }
            }catch(error){

              this.$message({
                type: "error",
                showClose: true,
                message: error.response ? error.response.data.message : error.message,
              });

            }finally{

              this.citiesLoading = false;

            }

          }

        }

      }else{

        let stateIds = [];

        this.citiesGroup = [];
          
        this.selectedStates.forEach(state => {

          stateIds.push(state.id);

          let obj = {
            state_id:state.id,
            state_name: state.name + " (Selecting this option will select all the cities/districts available in this state.)",
            cities:[]
          }

          this.citiesGroup.push(obj);

        });

        if(item.length > 1){

          item.forEach(state =>{

            stateIds.push(state.id);
            
            let obj = {
              state_id:state.id,
              state_name: state.name + " (Selecting this option will select all the cities/districts available in this state.)",
              cities:[]
            }

            this.citiesGroup.push(obj);
            
          });

        }else{

          stateIds.push(item.id);

          let obj = {
            state_id:item.id,
            state_name: item.name,
            cities:[]
          }
          
          if(this.citiesGroup.some(groupObj => groupObj == obj)){

            this.citiesGroup.push(obj);

          }

        }

        this.citiesLoading = true;
        try{
          let cityRes = await this.$axios.get('/state/cities/all',{params:{states:stateIds}})
          if(cityRes.data.status_code == "1010"){

            if(this.citiesList.length > 0){

              this.citiesList = [...this.citiesList,...cityRes.data.cities.filter(obj2 => !this.citiesList.some(obj1 => obj1.id === obj2.id))];

              this.citiesList = this.citiesList.filter((city) =>{

                if(stateIds.some(id => id == city.state_id));

                return city;

              })

              this.citiesGroup.forEach(group => {

                this.citiesList.forEach(city =>{

                  if(city.state_id == group.state_id){

                    if(!group.cities.some((groupedCity) => groupedCity.name == city.name)){

                      group.cities.push(city);

                    }

                  }

                })

              })

            }else{

              this.citiesList = cityRes.data.cities;

              this.citiesGroup = [];

              this.selectedStates.forEach(state => {
                stateIds.push(state.id);

                let obj = {
                  state_id:state.id,
                  state_name: state.name + " (Selecting this option will select all the cities/districts available in this state.)",
                  cities:[]
                }

                this.citiesGroup.push(obj);

              });

              this.citiesGroup.forEach(group => {

                this.citiesList.forEach(city =>{

                  if(city.state_id == group.state_id){

                    if(!group.cities.some((groupedCity) => groupedCity.name == city.name)){

                      group.cities.push(city);

                    }

                  }

                })
                
              })

            }

          }
        }catch(error){

          this.$message({
            type: "error",
            showClose: true,
            message: error.response ? error.response.data.message : error.message,
          });

        }finally{

          this.citiesLoading = false;

        }

      }

    },
    async stateRemoval(item,i){

      if(item.length > 1){

        this.selectedCities = this.selectedCities.filter((city) => {

          if(!item.some(state => state.id == city.state_id)){

            return city;

          }

        });

        this.citiesList = this.citiesList.filter((city)=>{

          if(!item.some(state => state.id == city.state_id)){

            return city;

          }

        });

        this.citiesGroup = [];

        this.selectedStates.forEach(selectedState => {

          if(!item.some(state => state.id == selectedState.id)){

            let obj = {
              state_id:selectedState.id,
              state_name: selectedState.name + " (Selecting this option will select all the cities/districts available in this state.)",
              cities:[]
            }

            this.citiesGroup.push(obj);

          }

        });

        this.citiesGroup.forEach(group => {

          this.citiesList.forEach(city =>{

            if(city.state_id == group.state_id){

              group.cities.push(city);

            }

          });

        });

      }else{

        this.selectedCities = this.selectedCities.filter((city) => {

          if(city.state_id != item.id){

            return city;

          }

        });

        this.citiesList = this.citiesList.filter((city)=>{

          if(city.state_id != item.id){

            return city;

          }

        });

        this.citiesGroup = [];

        this.selectedStates.forEach(state => {

          if(state.id != item.id){

            let obj = {
              state_id:state.id,
              state_name: state.name + " (Selecting this option will select all the cities/districts available in this state.)",
              cities:[]
            }

            this.citiesGroup.push(obj);

          }

        });

        this.citiesGroup.forEach(group => {

          this.citiesList.forEach(city =>{

            if(city.state_id == group.state_id){

              group.cities.push(city);

            }

          });

        });

      }

    },
    async citySelection(item,i){

      let state = this.statesList.find(state => state.id == item.state_id);

      if(state){

        if(!this.selectedStates.some(selectedState => selectedState.id == state.id)){

          this.selectedStates.push(state);

        }

      }

    },
    async cityRemoval(item,i){

      if(this.selectedCities.length == 1){

        // this.selectedStates = this.selectedStates.filter((state)=>{
        //   if(state.id != item.state_id){
        //     return state
        //   }
        // });

      }

    },
    
    removeRulePriceHandle(item,subItem,subItemIndex){

      if(!this.tourIsActive){
      
        MessageBox.confirm(
          "Are you sure ? The action is irreversible.", 
          "Warning", 
          {
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            type: "warning",
          }
        ).then(async ()=>{

          if(this.activeShipping.id == 2){

            let findExistingRulePrice = this.tempShippingRulePrices.findIndex((tempRule) => tempRule.id == subItem.id);

            if(findExistingRulePrice != -1){

              let loader = Loading.service({
                fullscreen: true,
                text: 'Removing shipping mode. Please wait!',
              });

              try{
                let res = await this.$axios.delete(`shipping/region/rule/price/remove/${subItem.id}`);
                if(res.data.status_code == "1168"){

                  this.$notify({
                    type: "success",
                    title: "Success",
                    message: res.data.message,
                  });

                  item.prices.splice(subItemIndex,1);

                }
              }catch(error){

                this.$message({
                  type: 'error',
                  showClose: true,
                  message: error.response ? error.response.data.message : error.message,
                });

              }finally{

                loader.close();

              }

            }else{

              if(item.prices.length > 2){

                item.prices.splice(subItemIndex,1);

                for( let i = 0; i < item.prices.length; i++){

                  if(i + 1 == item.prices.length){
                  
                    item.prices[i].from = parseFloat(parseFloat(item.prices[i - 1].to)  + 0.01).toFixed(2);
                    item.prices[i].to = "";

                  }else if(i != 0){
                    
                    item.prices[i].from = parseFloat(parseFloat(item.prices[i - 1].to)  + 0.01).toFixed(2);
                    item.prices[i].to = parseFloat(parseFloat(item.prices[i].from)  + 0.01).toFixed(2);
                  
                  }

                }

              }

            }

          }else if(this.activeShipping.id == 3){

            let findExistingRulePrice = this.tempShippingRulePrices.findIndex((tempRule) => tempRule.id == subItem.id);

            if(findExistingRulePrice != -1){

              let loader = Loading.service({
                fullscreen: true,
                text: 'Removing shipping mode. Please wait!',
              });

              try{
                let res = await this.$axios.delete(`shipping/region/rule/price/remove/${subItem.id}`);
                if(res.data.status_code == "1168"){

                  this.$notify({
                    type: "success",
                    title: "Success",
                    message: res.data.message,
                  });

                  item.prices.splice(subItemIndex,1);

                }
              }catch(error){

                this.$message({
                  type: 'error',
                  showClose: true,
                  message: error.response ? error.response.data.message : error.message,
                });

              }finally{

                loader.close();

              }

            }else{

              if(item.prices.length > 2){

                item.prices.splice(subItemIndex,1);

                for( let i = 0; i < item.prices.length; i++){

                  if(i + 1 == item.prices.length){
                  
                    item.prices[i].from = parseFloat(parseFloat(item.prices[i - 1].to)  + 0.01).toFixed(2);
                    item.prices[i].to = "";

                  }else if(i != 0){
                    
                    item.prices[i].from = parseFloat(parseFloat(item.prices[i - 1].to)  + 0.01).toFixed(2);
                    item.prices[i].to = parseFloat(parseFloat(item.prices[i].from)  + 0.01).toFixed(2);
                  
                  }

                }

              }

            }

          }

        }).catch(() => {})
      
      }

    },
    removeRuleHandle(listedRule,index){

      MessageBox.confirm(
        "Are you sure you want to delete the shipping rule? The action is irreversible.", 
        "Warning", 
        {
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "warning",
        }
      ).then(async ()=>{

        let findExistingRule = this.tempShippingRules.findIndex((tempRule) => tempRule.id == listedRule.id);

        if(findExistingRule != -1){

          let loader = Loading.service({
            fullscreen: true,
            text: 'Removing shipping rule. Please wait!',
          });

          try{
            let res = await this.$axios.delete(`/shipping/region/rule/remove/${listedRule.id}`);
            if(res.data.status_code == "1167"){

              this.$notify({
                type: "success",
                title: "Success",
                message: res.data.message,
              });

              this.shippingRules.splice(index,1);

              if(this.shippingRules.length == 0){

                this.addMoreForm();

              }

              this.$store.commit("orders_module/CLEAR_MODULE");

            }
          }catch(error){

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response ? error.response.data.message : error.message,
            });

          }finally{

            loader.close();

          }

        }else{

          if(this.shippingRules.length > 1){

            this.shippingRules.splice(index,1);

          }else{

            this.shippingRules = [];
            this.addMoreForm();

          }

          this.$notify({
            type: "success",
            title: "Success",
            message: "The shipping rule has been successfully removed.",
          });

        }

      }).catch(() => {})

    },
    changeShippingMethodHandle(action,option){

      if(action == 'with-confirmation'){

        MessageBox.confirm(
          "Are you sure you want to change the shipping mode? Previous shipping mode data will be discarded permanently.", 
          "Warning", 
          {
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            type: "warning",
          }
        ).then(async ()=>{

          if((this.tempRegion.shipping)){

            if(this.tempRegion.shipping.type != option.title){

              let loader = Loading.service({
                fullscreen: true,
                text: 'Removing shipping mode. Please wait!',
              });

              try{
                let res = await this.$axios.delete(`/shipping/region/mode/remove/${this.tempRegion.shipping_id}`);
                if(res.data.status_code == "1166"){

                  this.$notify({
                    type: "success",
                    title: "Success",
                    message: res.data.message,
                  });

                  this.tempRegion.shipping = null;
                  this.tempRegion.shipping_id = "";

                  let current = this.activeShipping;

                  this.shippingOptions = this.shippingOptions.map((element) => {

                    if(element.id == option.id){

                      return current;

                    }else{

                      return element;

                    }

                  });

                  this.activeShipping = option;
                  this.showDropDown = false;

                  if(this.activeShipping.title == "Rate By Weight"){

                    Swal.fire({
                      title: 'Note',
                      text: this.activeShipping.note,
                      icon: 'warning',
                      showCancelButton: false,
                      showConfirmButton: true,
                      confirmButtonColor: '#15223D',
                      cancelButtonColor: '#15223D',
                      confirmButtonText: 'OK'
                    }).then((result) => {

                      if(result.isConfirmed){
                        
                      }

                    });

                  }

                  this.$store.commit("orders_module/CLEAR_MODULE");

                }
              }catch(error){

                this.$message({
                  type: 'error',
                  showClose: true,
                  message: error.response ? error.response.data.message : error.message,
                });

              }finally{

                loader.close();

              }

            }

          }else{

            let current = this.activeShipping;

            this.shippingOptions = this.shippingOptions.map((element) => {

              if(element.id == option.id){

                return current;

              }else{

                return element;

              }

            });

            this.activeShipping = option;
            this.showDropDown = false;

            if(this.activeShipping.title == "Rate By Weight"){

              Swal.fire({
                title: 'Note',
                text: this.activeShipping.note,
                icon: 'warning',
                showCancelButton: false,
                showConfirmButton: true,
                confirmButtonColor: '#15223D',
                cancelButtonColor: '#15223D',
                confirmButtonText: 'OK'
              }).then((result) => {

                if(result.isConfirmed){
                  
                }

              });

            }

            this.$notify({
              type: "success",
              title: "Success",
              message: "The shipping mode has been successfully removed.",
            });

          }

        }).catch(() => {})

      }else if(action == 'without-confirmation'){
        
        let current = this.activeShipping;

        this.shippingOptions = this.shippingOptions.map((element) => {

          if(element.id == option.id){

            return current;

          }else{

            return element;

          }

        });

        this.activeShipping = option;
        this.showDropDown = false;

        console.log(this.activeShipping,'active shipping');

        if(this.activeShipping.title == "Rate By Weight"){

          Swal.fire({
            title: 'Note',
            text: this.activeShipping.note,
            icon: 'warning',
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonColor: '#15223D',
            cancelButtonColor: '#15223D',
            confirmButtonText: 'OK'
          }).then((result) => {

            if(result.isConfirmed){
              
            }

          });

        }

      }

    },
    addFields(child_index, rule_index){

      if(this.activeShipping.id == 2){

        if(parseFloat(this.shippingRules[rule_index].weights[child_index - 1].to) > 0){

          let obj = {
          id: uuidv4(),
          from: parseFloat(parseFloat(this.shippingRules[rule_index].weights[child_index].from)  + 0.01).toFixed(2),
          to: '',
          price: '',
          non_cod_price:'',
        }

        this.shippingRules[rule_index].weights[child_index].to = obj.from;
        this.shippingRules[rule_index].weights.push(obj);

        this.shippingRules[rule_index].weights[this.shippingRules[rule_index].weights.length - 1].from = (parseFloat(obj.from) + 0.01).toFixed(2);

        }else{

          this.$notify({
            type: "warning",
            title: "Range Required",
            message: "Fields of previous range are required.",
          });

        }

      }else if(this.activeShipping.id == 3){

        if(parseFloat(this.shippingRules[rule_index].prices[child_index - 1].to) > 0){

          let obj = {
            id: uuidv4(),
            from: parseFloat(parseFloat(this.shippingRules[rule_index].prices[child_index].from) + 0.01).toFixed(2),
            to: '',
            price: '',
            non_cod_price:'',
          }

          this.shippingRules[rule_index].prices[child_index].to = obj.from;
          this.shippingRules[rule_index].prices.push(obj);

          this.shippingRules[rule_index].prices[this.shippingRules[rule_index].prices.length - 1].from = (parseFloat(obj.from) + 0.01).toFixed(2);

        }else{

          this.$notify({
            type: "warning",
            title: "Range Required",
            message: "Fields of previous range are required",
          });

        }

      }

    },
    inpFrom(child_index, rule_index){

      if(this.activeShipping.id == 2){

        if(this.shippingRules[rule_index].weights[child_index-1]){

          this.shippingRules[rule_index].weights[child_index-1].to = parseFloat(parseFloat(this.shippingRules[rule_index].weights[child_index].from) - 0.01).toFixed(2);
        
        }

      }else if(this.activeShipping.id == 3){

        if(this.shippingRules[rule_index].prices[child_index-1]){

          this.shippingRules[rule_index].prices[child_index-1].to = parseFloat(parseFloat(this.shippingRules[rule_index].prices[child_index].from) - 0.01).toFixed(2);
        
        }

      }

    },
    inpTo(child_index, rule_index){

      if(this.activeShipping.id == 2){

        if(this.shippingRules[rule_index].weights[child_index+1]){

          this.shippingRules[rule_index].weights[child_index+1].from = parseFloat(parseFloat(this.shippingRules[rule_index].weights[child_index].to) + 0.01).toFixed(2);
        
        }

      }else if(this.activeShipping.id == 3){
        
        if(this.shippingRules[rule_index].prices[child_index+1]){
         
          this.shippingRules[rule_index].prices[child_index+1].from = parseFloat(parseFloat(this.shippingRules[rule_index].prices[child_index].to) + 0.01).toFixed(2);
        
        }

      }

    },
    addMoreForm(){

      console.log(this.activeShipping.id);

      let newRule = {}

      if(this.activeShipping.id == 2){

        newRule = {
          id: uuidv4(),
          name: "",
          delivery_details: "",
          isActive: 1,
          weights: [
            {
              id: uuidv4(),
              from: 0.0,
              to: 1,
              price: "",
              non_cod_price: "",
            },
            {
              id: uuidv4(),
              from: 1,
              to: '',
              price: "",
              non_cod_price: "",
            },
          ],
        };

      }else if(this.activeShipping.id == 3){

        newRule = {
          id: uuidv4(),
          name: "",
          delivery_details: "",
          isActive: 1,
          prices: [
            {
              id: uuidv4(),
              from: 0.0,
              to: 1,
              price: "",
              non_cod_price: "",
            },
            {
              id: uuidv4(),
              from: 1,
              to: '',
              price: "",
              non_cod_price: "",
            },
          ],
        };

      }else if(this.activeShipping.id == 4){

        newRule = {
          id: uuidv4(),
          store_name: "",
          store_contact_no: "",
          store_address: "",
          store_city_id: "",
          isActive: 1,
        };

      }else if(this.activeShipping.id == 5){

        // newRule = {
        //   id: uuidv4(),
        //   name: "",
        //   delivery_details:'',
        //   price: '',
        //   non_cod_price:'',
        //   isActive: 1,
        // };

        newRule = {
          id: uuidv4(),
          name: "",
          delivery_details:'',
          isActive: 1,
          prices: [
            {
              id: uuidv4(),
              price: '',
              non_cod_price:"",
            }
          ],
        };

      }

      this.shippingRules.push(_.cloneDeep(newRule));

      console.log(this.shippingRules)

    },
    async validate(action){
      
      if(action == "update"){

        this.$refs.updateShippingObserver.validate().then(success => {

          if(!success){

            const errors = Object.entries(this.$refs.updateShippingObserver.errors)
            .map(([key, value]) => ({ key, value })).filter(error => error["value"].length);

            this.$refs.updateShippingObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center" });

          }else{

            this.updateRegion();

          }

        });

      }

    },
    checkDuplicateShippingOption(item){

      if(this.activeShipping.id == 2){
        //rate by weight
        
        item.name = item.name.trim();

        if(item.name != ''){

          let count = 0;

          for(let i = 0; i < this.shippingRules.length; i++){

            if(this.shippingRules[i].name == item.name){

              count++;

            }

          }

          if(count > 1){

            this.$notify({
              type: 'error',
              title: 'Option Name Already Exists',
              message: "An option already exists with the entered name. Kindly enter a unique one.",
            });

            item.name = '';

          }

        }

      }else if(this.activeShipping.id == 3){
        //rate by price
        
        item.name = item.name.trim()

        if(item.name != ''){

          let count = 0;

          for(let i = 0; i < this.shippingRules.length; i++){

            if(this.shippingRules[i].name == item.name){

              count++;

            }

          }

          if(count > 1){

            this.$notify({
              type: 'error',
              title: 'Option Name Already Exists',
              message: "An option already exists with the entered name. Kindly enter a unique one.",
            });

            item.name = '';

          }

        }

      }else if(this.activeShipping.id == 4){
        //store pickup only
        
        item.store_name = item.store_name.trim();

        if(item.store_name != ''){

          let count = 0;

          for(let i = 0; i < this.shippingRules.length; i++){

            if(this.shippingRules[i].store_name == item.store_name){

              count++;

            }

          }

          if(count > 1){

            this.$notify({
              type: 'error',
              title: 'Store Name Already Exists',
              message: "A store already exists with the entered name. Kindly enter a unique one.",
            });

            item.store_name = ''

          }

        }

      }else if(this.activeShipping.id == 5){
        //flat rate

        item.name = item.name.trim()

        if(item.name != ''){

          let count = 0;

          for(let i = 0; i < this.shippingRules.length; i++){

            if(this.shippingRules[i].name == item.name){

              count++;

            }

          }

          if(count > 1){

            this.$notify({
              type: 'error',
              title: 'Option Name Already Exists',
              message: "An option already exists with the entered name. Kindly enter a unique one.",
            });

            item.name = ''

          }

        }

      }

    },
    async updateRegion(){

      let formData = new FormData();

      formData.append('region_id',this.$route.params.id);
      formData.append('name', this.region_name.trim());
      formData.append('is_cod_supported', this.isCodSupport ? 1 : 0);
      formData.append('is_non_cod_different', this.isNonCodDifferent ? 1 : 0);

      formData.append('cities', JSON.stringify(this.selectedCities.map((city) => {return city.id})));

      formData.append('shipping_method_type',this.activeShipping.title);

      if(this.activeShipping.id == 4){

        let tempRules = _.cloneDeep(this.shippingRules);

        tempRules.map((rule) => {

          rule.store_city_id = rule.store_city_id.id;

          return rule;

        });

        formData.append('shipping_rules', JSON.stringify(tempRules));

      }else{

        formData.append('shipping_rules', JSON.stringify(this.shippingRules));

      }

      this.editShippingLoader = true;
      try{
        let res = await this.$axios.post('/shipping/region/update', formData);
        if(res.data.status_code == '1014'){
            
          this.$notify({
            type: 'success',
            title: 'Success',
            message: res.data.message,
          });

          this.$store.dispatch("shipping_module/fetchStoreRegionsRequest",{ size: 12, page: 1, loadData: 'new'});

          this.$router.push('/shipping');

        }
      }catch(error){
        
        if(error.response){

          if(error.response.data.error.name){

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response.data.error.name[0],
            });

          }else if(error.response.data.id){

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response.data.id[0],
            });

          }else{

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response.data.message,
            });

          }

        }else{

          this.$message({
            type: 'error',
            showClose: true,
            message: error.message,
          });

        }

      }finally{

        this.editShippingLoader = false;

      }
      
    },
    updateRules(shipping){

      let tempShippingRules = [];

      let tempShippingRulePrices = [];

      if(shipping.type == "Flat Rate"){

        shipping.shipping_rules.forEach((rule) => {

          let tempRule = {
            id: rule.id,
            name: rule.name,
            delivery_details: rule.delivery_details,
            isActive: rule.isActive,
            prices:[
              {
                id: rule.rule_prices[0].id,
                price: rule.rule_prices[0].price,
                non_cod_price: rule.rule_prices[0].non_cod_price,
              }
            ],

          };

          tempShippingRules.push(tempRule);

        });

      }else if(shipping.type == "Store Pickup Only"){

        shipping.shipping_rules.forEach((rule) => {

          let tempRule = {
            id: rule.id,
            store_name: rule.store_name,
            store_contact_no: rule.store_contact_no,
            store_address: rule.store_address,
            store_city_id: rule.city,
            isActive: 1,
          }

          tempShippingRules.push(tempRule);

        });

      }else if(shipping.type == "Rate By Price"){

        shipping.shipping_rules.forEach((rule) => {

          let tempRule = {
            id: rule.id,
            name: rule.name,
            delivery_details: rule.delivery_details,
            isActive: 1,
            prices: rule.rule_prices
          }

          tempShippingRules.push(tempRule);

          rule.rule_prices.forEach((rulePrice) => {

            tempShippingRulePrices.push(rulePrice);

          });

        });

      }else if(shipping.type == "Rate By Weight"){

        shipping.shipping_rules.forEach((rule) => {

          let tempRule = {
            id: rule.id,
            name: rule.name,
            delivery_details: rule.delivery_details,
            isActive: 1,
            weights: rule.rule_prices
          }

          tempShippingRules.push(tempRule);

          rule.rule_prices.forEach((rulePrice) => {

            tempShippingRulePrices.push(rulePrice);

          });

        });

      }

      setTimeout(()=>{

        this.shippingRules = _.cloneDeep(tempShippingRules);

        this.tempShippingRules = _.cloneDeep(this.shippingRules);

        this.tempShippingRulePrices = _.cloneDeep(tempShippingRulePrices);

      },100);

    },
    goBack(){

      if(this.selectedCities || this.shippingRules.length > 0 || this.isTouched){

        this.isGoBack = true;
        this.$router.push('/shipping');
        this.$store.dispatch("shipping_module/fetchStoreRegionsRequest",{ size: 12, page: 1, loadData: 'new'});

      }else{

        this.isGoBack = true;
        this.$router.push('/shipping');
        this.$store.dispatch("shipping_module/fetchStoreRegionsRequest",{ size: 12, page: 1, loadData: 'new'});

      }
      
    },
    async loadStates(){

      let countryIds = [];

      this.selectedCountries.forEach(country => {

        countryIds.push(country.id);

      });

      this.statesLoading = true;
      try{
        let res = await this.$axios.get('/country/states/all',{params:{countries:countryIds}});
        if(res.data.status_code == "1010"){

          if(this.selectedStates){
            
            this.selectedStates = this.selectedStates.filter((state)=>{

              if(countryIds.includes(state.country_id)){

                return state;

              }

            });

            this.statesList = [...this.selectedStates,...res.data.states];

          }else{

            this.statesList = res.data.states;

          }

          this.selectedStates = this.statesList;

        }
      }catch(error){

        this.$message({
          type: "error",
          showClose: true,
          message: error.response ? error.response.data.message : error.message,
        });

      }finally{

        this.statesLoading = false;

      }

    },
    async loadCities(){

      let stateIds = [];

      this.selectedStates.forEach(state => {

        stateIds.push(state.id);

      });

      this.citiesLoading = true;
      try{
        let res = await this.$axios.get('/state/cities/all',{params:{states:stateIds}});
        if(res.data.status_code == "1010"){
          
          if(this.selectedCities){

            if(stateIds.length > 0){

              this.selectedCities = this.selectedCities.filter((city)=>{

                if(stateIds.includes(city.state_id)){
                  
                  return city;

                }

              });

            }
            
            this.citiesList = [...this.selectedCities,...res.data.cities];

          }else{
            
            this.citiesList = res.data.cities;

          }

          this.selectedCities = this.citiesList;

        }
      }catch(error){

        this.$message({
          type: "error",
          showClose: true,
          message: error.response ? error.response.data.message : error.message,
        });

      }finally{

        this.citiesLoading = false;

      }

    }
  },
  async beforeMount(){

    try{

      if(this.$route.params.id){

        this.regionLoader = true;
        try{
          let response = await this.$axios.get(`/shipping/region/${this.$route.params.id}`);
          if(response.data.status_code == '1013'){

            this.regionLoader = false;

            let region = response.data.region;

            this.isCodSupport = region.shipping.is_cod_support;
            this.isNonCodDifferent = region.shipping.is_non_cod_different;

            if(region.shipping.handling_fee){

              this.handlingFee.isActive = 1;
              this.handlingFee.fee = region.shipping.handling_fee;

            }

            this.region_name = region.name;

            this.temp_region_name = region.name;

            this.region_id = this.$route.params.id;

            this.tempRegion = _.cloneDeep(region);

            let countries = [];
            let states = [];
            let cities = [];

            region.related_cities.forEach((relatedCity)=>{

              if(!countries.some(country => country.id == relatedCity.city.country.id)){
                
                countries.push(relatedCity.city.country);

                let obj = {
                  country_id:relatedCity.city.country.id,
                  country_name: relatedCity.city.country.name + " (Selecting this option will select all the states available in this country.)",
                  states:[]
                }

                this.statesGroup.push(obj);

              }

              if(!states.some(state => state.id == relatedCity.city.state.id)){

                states.push(relatedCity.city.state);

                let obj = {
                  state_id:relatedCity.city.state.id,
                  state_name: relatedCity.city.state.name + " (Selecting this option will select all the cities/districts available in this state.)",
                  cities:[]
                }

                this.citiesGroup.push(obj);

              }
              if(!cities.some(city => city.id == relatedCity.city.id)){

                let obj = {
                  id: relatedCity.city.id,
                  name: relatedCity.city.name,
                  state_id: relatedCity.city.state_id,
                  state_code: relatedCity.city.state_code,
                  country_id: relatedCity.city.country_id,
                  country_code: relatedCity.city.country_code,
                  latitude: relatedCity.city.latitude,
                  longitude: relatedCity.city.longitude,
                  wikiDataId: relatedCity.city.wikiDataId,
                }

                cities.push(obj);

              }

            });

            this.orgCountries = countries;
            this.selectedCountries = countries;

            this.selectedStates = states;
            this.statesList = states;
            this.orgStates = states;

            this.selectedCities = cities;
            this.citiesList = cities;
            this.orgCities = cities;

            this.citiesGroup.forEach(group => {

              this.citiesList.forEach(city =>{

                if(city.state_id == group.state_id){

                  group.cities.push(city);

                }

              });

            });

            this.shippingOptions.forEach(option=>{

              if(region.shipping.type == option.title){

                this.changeShippingMethodHandle('without-confirmation',option);

                this.updateRules(region.shipping);

              }

            });

          }
        }catch(error){

          this.regionLoader = false;

          this.$router.push('/shipping');

          if(error.response){

            if(error.response.data.error.includes("deleted or isn't available")){

              this.$message({
                type: 'error',
                showClose: true,
                message: error.response.data.error,
              });

            }else{

              this.$message({
                type: 'error',
                showClose: true,
                message: error.response.data.message,
              });

            }

          }else{

            this.$message({
              type: 'error',
              showClose: true,
              message: error.message,
            });

          }

          return

        }

        if(!this.storeCountries || this.storeCountries.length == 0){

          this.$store.dispatch('countries_module/fetchStoreCountriesRequest');

        }else{

          this.countriesList = this.storeCountries;

          this.countriesList.push(this.selectedCountries);

        }

        let countryIds = [];

        this.selectedCountries.forEach(country => {

          countryIds.push(country.id);

        });

        this.statesLoading = true;
        this.citiesLoading = true;
        try{
          let res = await this.$axios.get('/country/states/all',{params:{countries:countryIds}});
          if(res.data.status_code == "1010"){
            
            if(this.statesList.length > 0){

              let uniqueStates = res.data.states.filter((state) => {

                if(!this.statesList.some((listedState) => listedState.name == state.name)){

                  return state;

                }

              });

              this.statesList = [...this.statesList,...uniqueStates];

              if(this.statesGroup.length > 0){

                this.statesGroup.forEach(group => {

                  this.statesList.forEach(state =>{

                    if(state.country_id == group.country_id){

                      group.states.push(state);

                    }

                  });

                });

              }

            }else{

              this.statesList = res.data.states;

            }

            let stateIds = [];

            this.statesList.forEach(state => {

              stateIds.push(state.id);

            });

            this.citiesLoading = true;
            try{
              let resCities = await this.$axios.get('/state/cities/all',{params:{states:stateIds}});
              if(resCities.data.status_code == "1010"){

                if(this.citiesList.length > 0){

                  this.citiesList = [...this.citiesList,...resCities.data.cities];

                  this.citiesGroup.forEach(group => {

                    this.citiesList.forEach(city =>{

                      if(city.state_id == group.state_id){

                        if(!group.cities.some((groupedCity) => groupedCity.name == city.name)){

                          group.cities.push(city);

                        }

                      }

                    });

                  });

                }else{

                  this.citiesList = resCities.data.cities;

                }

              }
            }catch(error){

              this.$message({
                type: "error",
                showClose: true,
                message: error.response ? error.response.data.message : error.message,
              });

            }finally{

              this.citiesLoading = false;

            }

          }
        }catch(error){

          this.$message({
            type: "error",
            showClose: true,
            message: error.response ? error.response.data.message : error.message,
          });

        }finally{

          this.statesLoading = false;
          this.citiesLoading = false;

        }

      }
    }catch(error){

      this.regionLoader = false;

      if(error.response){

        if(error.response.data.error.includes("deleted or isn't available")){

          this.$message({
            type: 'error',
            showClose: true,
            message: error.response.data.error,
          });

        }else{

          this.$message({
            type: 'error',
            showClose: true,
            message: error.response.data.message,
          });

        }

      }else{

        this.$message({
          type: 'error',
          showClose: true,
          message: error.message,
        });

      }

    }

    this.editShippingLoader = false;
    
  },
  mounted(){

  },
  computed:{
    ...mapGetters({
      settings:'settings_module/settings',
      storeCountries:'countries_module/countries',
    })
  },
  watch: {
    storeCountries:{
      handler:function(value){

        this.countriesList = value;

        this.selectedCountries.forEach(selectedCountry =>{

          if(!this.countriesList.some(country => country.id == selectedCountry.id)){

            this.countriesList.push(selectedCountry);

          }

        });

      },deep:true
    },
    selectedCountries:{
      handler:function(value){
        
        if(value.length > 0){
          
          this.isTouched = true;

        }else{

          this.statesList = [];
          this.selectedStates = [];
          this.citiesList = [];
          this.selectedCities = [];

          this.isTouched = false;

        }

      },
      deep: true,
    },
    selectedStates:{
      handler:function(value){

        if(value.length > 0){

          this.isTouched = true;

        }else{

          this.citiesList = [];

          this.isTouched = false;

        }

      },
      deep: true,
    },
    activeShipping:{
      handler:function(value){

        if(value.id == 2){

          this.rule = {
            id: uuidv4(),
            name: "",
            business_delivery: "",
            weights: [
              {
                id: uuidv4(),
                from: 0.0,
                to: 1,
                price: "",
                non_cod_price:'',
              },
              {
                id: uuidv4(),
                from: 1,
                to: '',
                price: "",
                non_cod_price:'',
              },
            ],
          };

        }else if(value.id == 3){

          this.rule = {
            id: uuidv4(),
            name: "",
            delivery_details: "",
            isActive: 1,
            prices: [
              {
                id: uuidv4(),
                from: 0.0,
                to: 1,
                price: "",
                non_cod_price:'',
              },
              {
                id: uuidv4(),
                from: 1,
                to: '',
                price: "",
                non_cod_price:'',
              },
            ],
          };

        }else if(this.activeShipping.id == 4){

          this.rule = {
            id: uuidv4(),
            store_name: "",
            store_contact_no: "",
            store_address: "",
            store_city_id: "",
            isActive: 1,
          };

        }else if(this.activeShipping.id == 5){

          this.rule = {
            id: uuidv4(),
            name: "",
            delivery_details:'',
            isActive: 1,
            prices: [
              {
                id: uuidv4(),
                price: '',
                non_cod_price:"",
              }
            ],
          };

        }

        this.shippingRules = [_.cloneDeep(this.rule)];

        if(value){

          this.isTouched = true;

        }else{

          this.isTouched = false;

        }

      },
      deep: true,
    },
    'region_name':{
      handler:function(value){

        if(value){

          this.isTouched = true;

        }else{

          this.isTouched = false;

        }

      }
    }
  },
};
</script>
<style scoped>
  .multiselect {
    color: black;
  }
</style>